<template>
  <v-form v-model="valid" ref="form">
    <select-romaneio class="mr-2"></select-romaneio>
    <div class="form-btn">
      <v-btn outline @click="gerarRelatorio" color="primary">Gerar relatório</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <v-snackbar :color="'pink accent-1'" v-model="snackbar" :top="true">
      {{ text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../../main"
import SelectRomaneio from "../../romaneio/selectRomaneio.vue"
import { RelatorioRomaneio } from "../../../servicos/relatorios/relatorioRomaneio"
const servicoRelatorioRomaneio = new RelatorioRomaneio()
export default {
  // eslint-disable-next-line
  props: { origem: String },
  components: {
    SelectRomaneio
  },
  data() {
    return {
      snackbar: false,
      text: "Gerando arquivo para impressão. Aguarde!",
      codRomaneio: null
    }
  },
  created() {
    serverBus.$on("romaneioSelected", value => {
      this.codRomaneio = value.Id
    })
  },
  methods: {
    gerarRelatorio() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        if (this.origem === "Itens-nao-devolvidos") {
          servicoRelatorioRomaneio.getItensNaoVendidosPorIdRomaneio(this.codRomaneio).then(
            response => {
              var blob = new Blob([response.data], { type: "application/pdf" })
              var link = document.createElement("a")
              link.href = window.URL.createObjectURL(blob)
              var fileName = `Romaneio_${this.codRomaneio}_ItensDevolvidos.xlsx`
              link.download = fileName
              link.click()
              this.snackbar = false
            },
            err => {
              this.snackbar = true
              this.text = err.response.data[0].detail
            }
          )
        } else if (this.origem === "Itens-nao-ficaram-em-saida") {
          servicoRelatorioRomaneio.getItensNaoFicaramEmSaida(this.codRomaneio).then(
            response => {
              var blob = new Blob([response.data], { type: "application/pdf" })
              var link = document.createElement("a")
              link.href = window.URL.createObjectURL(blob)
              var fileName = `Romaneio_${this.codRomaneio}_ItensDevolvidos.xlsx`
              link.download = fileName
              link.click()
              this.snackbar = false
            },
            err => {
              this.snackbar = true
              this.text = err.response.data[0].detail
            }
          )
        } else {
          servicoRelatorioRomaneio.imprimir(this.codRomaneio).then(
            response => {
              var blob = new Blob([response.data], { type: "application/pdf" })
              var link = document.createElement("a")
              link.href = window.URL.createObjectURL(blob)
              var fileName = `Romaneio_${this.codRomaneio}.xlsx`
              link.download = fileName
              link.click()
              this.snackbar = false
            },
            err => {
              this.snackbar = true
              this.text = err.response.data[0].detail
            }
          )
        }
      }
    },
    voltar() {
      this.$router.push({ name: "Relatorios" })
    }
  }
}
</script>
